import Footer from './Footer';

function Contact(){
	return(
		<div>
			<div className="bg-[#1E1E1E] bg-[url('./images/contacts_bg.svg')] bg-cover lg:bg-center lg:h-screen flex flex-col content-center justify-center font-rouna ">
				<div className="text-center text-4xl text-white font-rouna py-10 lg:text-7xl 3xl:text-9xl 4xl:text-[12rem]">Contact Me</div>
				
				<div className="text-gray-200 font-rouna text-center text-xl lg:pb-10 lg:text-xl 3xl:text-4xl 4xl:text-7xl">You can find me on the following platforms</div>
				
				<div className="flex flex-col flew-grow py-10 space-y-5 items-center justify-center text-center lg:space-y-10 4xl:space-y-20">
					<div className='flex flex-col justify-center space-y-5 flew-grow w-1/2 items-center lg:flex-row lg:space-x-10 4xl:space-x-20 lg:space-y-0'>
						<div className="p-6 3xl:py-10 4xl:py-20 grow w-full lg:w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md ">
							<a href="mailto:anony3039@gmail.com" className="">
								<h5 class="fa-solid fa-envelope mb-2 text-8xl lg:text-8xl 4xl:text-[10rem] font-bold tracking-tight text-white hover:text-profile-blue"></h5>
							</a>
							<p class="mb-3 font-normal text-gray-400 text-2xl lg:text-2xl 3xl:text-4xl 4xl:text-7xl">Mail me</p>
						</div>

						<div className="p-6 3xl:py-10 4xl:py-20 grow w-full lg:w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md">
							<a href="https://linkedin.com/in/itsanuragverma" className="">
								<h5 class="fa-brands fa-linkedin mb-2 text-8xl lg:text-8xl 4xl:text-[10rem] font-bold tracking-tight text-white hover:text-profile-blue"></h5>
							</a>
							<p class="mb-3 font-normal text-gray-400 text-2xl lg:text-2xl 3xl:text-4xl 4xl:text-7xl">LinkedIn</p>
						</div>
					</div>

					<div className='flex flex-col justify-center space-y-5 flew-grow w-1/2 items-center lg:flex-row lg:space-x-10 4xl:space-x-20 lg:space-y-0'>
						<div className="p-6 3xl:py-10 4xl:py-20 grow w-full lg:w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md">
							<a href="https://github.com/ultrahacx" className="">
								<h5 class="fa-brands fa-github mb-2 text-8xl lg:text-8xl 4xl:text-[10rem] font-bold tracking-tight text-white hover:text-profile-blue"></h5>
							</a>
							<p class="mb-3 font-normal text-gray-400 text-2xl lg:text-2xl 3xl:text-4xl 4xl:text-7xl">Find me on Github</p>
						</div>

						<div className="p-6 3xl:py-10 4xl:py-20 grow w-full lg:w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md">
							<a href="https://discordapp.com/users/540579701433958410" className="">
								<h5 class="fa-brands fa-discord mb-2 text-8xl lg:text-8xl 4xl:text-[10rem] font-bold tracking-tight text-white hover:text-profile-blue"></h5>
							</a>
							<p class="mb-3 font-normal text-gray-400 text-2xl lg:text-2xl 3xl:text-4xl 4xl:text-7xl">Chat with me on Discord</p>
						</div>
					</div>

				</div>

				
			</div>
			<Footer/>
		</div>
	)
}

export default Contact;
