function Footer(){
	return(
		<div className="relative bottom-0">
			<footer class="p-4 bg-[#272727] shadow flex md:items-center md:justify-between md:p-6 text-center">
				<span class="text-sm text-gray-500 dark:text-gray-400">© 2022 All rights reserved.
				</span>
				<span class="text-sm text-gray-500 dark:text-gray-400">
					Made with <span className="text-red-500 fa-solid fa-heart"></span> using 
					<a href="https://reactjs.org/" class="font-bold text-profile-blue"> ReactJS </a>
					and 
					<a href="https://tailwindcss.com/" class="font-bold text-profile-blue"> TailwindCSS </a>
				</span>
				

			</footer>
		</div>
	)
}

export default Footer;