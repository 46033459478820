import { useEffect } from "react";

function About(){
	useEffect(() => {
		fetch('https://api.github.com/users/ultrahacx')
		.then(response => {
			if(response.ok) return response.json();
		})
		.then(data => {
			document.getElementById("follower-count").innerHTML = data.followers;
		})
		.catch(error => {
			console.log(error);
		});
	
	},[])

	return(
		<div id="about" className="font-rouna flex items-center content-center lg:h-screen w-full bg-[#1E1E1E] bg-[url('./images/abstract_art.svg')] bg-cover bg-no-repeat ">
			<div className="pt-10 xl:pt-0">
				<div className="text-white text-center text-6xl lg:text-7xl 3xl:text-9xl 4xl:text-[14rem]">About Me</div>

				<div className="text-white flex flex-col items-center mx-4 my-10 text-lg xl:mx-40 xl:my-20 text-center xl:text-2xl 3xl:text-4xl 4xl:text-6xl 3xl:leading-normal 4xl:leading-normal">
					<p>My name is <span className="text-profile-blue">Anurag Verma</span>. I am a tech geek and like to develop thing.</p>
					<p>I`m currently living in <span className="text-profile-blue">India</span>. I am a second year <span className="text-profile-blue">Computer Science student</span>.</p>

					<br/>
					
					<p className="lg:pt-10 2xl:pt-0 xl:w-8/12 text-center">I love creating and managing backend services. I am a Linux lover and like to contribute to the <span className="text-profile-blue">open-source</span> community projects. I do a little bit of</p>
					<p>3D modeling, animation and Blender scripting in my free time. </p>
				</div>
				
				<div className="flex flex-col text-center">
					<div className="text-white text-4xl pb-2 4xl:pt-20 lg:text-5xl 3xl:text-8xl 4xl:text-[10rem]">Github Stats</div>
					<div className="text-gray-300 text-md pb-10 4xl:pb-40 lg:text-md 3xl:text-3xl 4xl:text-5xl">All Time Estimated Data</div>
					
					<div className="flex flex-col flew-grow items-center justify-center pb-10 lg:pt-0 space-y-5 lg:flex-row  lg:space-x-10 lg:space-y-0">
						
						<div className="p-6 3xl:py-10 4xl:py-20 grow w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md ">
							<div id="follower-count" className="text-white text-4xl 3xl:text-6xl 4xl:text-8xl xl:pb-2 3xl:pb-10">100</div>
							<div className="flex items-center justify-center text-xl space-x-2 3xl:text-4xl 4xl:text-5xl">
								<div className="fa-solid fa-user-group text-white"></div>
								<div className="text-white">Followers</div>
							</div>
						</div>

						<div className="p-6 3xl:py-10 4xl:py-20 grow w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md ">
							<div id="stars-count" className="text-white text-4xl 3xl:text-6xl 4xl:text-8xl xl:pb-2 3xl:pb-10">1,037</div>
							<div className="flex items-center justify-center text-xl space-x-2 3xl:text-4xl 4xl:text-5xl">
								<div className="fa-solid fa-star text-white"></div>
								<div className="text-white">Stars</div>
							</div>

						</div>

						<div className="p-6 3xl:py-10 4xl:py-20 grow w-1/2 4xl:w-9/12 lg:max-w-sm 3xl:max-w-2xl 4xl:max-w-4xl flex flex-col items-center justify-center bg-[#FFFF]/[0.1] backdrop-blur-lg rounded-lg border border-gray-200 shadow-md ">
							<div id="commits-count" className="text-white text-4xl 3xl:text-6xl 4xl:text-8xl xl:pb-2 3xl:pb-10">1,500</div>
							<div className="flex items-center justify-center text-xl space-x-2 3xl:text-4xl 4xl:text-5xl">
								<div className="fa-solid fa-code-commit text-white"></div>
								<div className="text-white">Commits</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About;