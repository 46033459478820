import './App.css';
import Nav from './components/Nav';
import LandingContent from './components/LandingContent'
import About from './components/About'
import MyProjects from './components/MyProjects';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <div className="App h-screen bg-gradient-radial">
        <Nav/>
        <LandingContent/>
        {/* <About/> */}
      </div>
      <About/>
      <MyProjects/>
      <Contact/>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
