import React from 'react';
import profile from '../images/avatar.png'
import Lottie from 'lottie-react'
import lottieAnim from '../lf30_editor_qqkhodqh.json'

function LandingContent(){
	return(
		<div id='landing-content' className='z-0 absolute top-0 left-0 w-full h-screen flex flex-wrap px-10 lg:flex-row flex-col items-center justify-center'>
			<div id="landing-info" className='lg:flex-auto font-rouna'>
				<div className=' text-center text-6xl xl:text-8xl 2xl:text-9xl 3xl:text-[10rem] 4xl:text-[14rem]'>
					<span className='text-white'>Hi, I am </span>
					<span className='text-profile-blue'>Anurag;</span>
				</div>
				<div className="hidden lg:block text-6xl xl:mt-[2rem] 3xl:mt-[3rem] 4xl:mt-[5rem] xl:text-2xl 2xl:text-4xl 3xl:text-[2.8rem] 4xl:text-6xl mt-10 text-white text-center tracking-widest">
					CS Student | Developer | Designer | 3D Enthusiast 
				</div>
			</div>

			<div id="landing-image" className='lg:w-1/3 lg:mt-0 p-0 mt-5'>
				<img src={profile} alt="" className='w-full'/>
				<div className="lg:hidden font-rouna text-xl mt-10 text-white text-center tracking-widest">
					CS Student | Developer | Designer | 3D Enthusiast 
				</div>
			</div>

			<div id="down-arrow" className="absolute w-full bottom-0 flex items-center justify-center">
				<Lottie animationData={lottieAnim} className="w-2/12 xl:w-1/12"></Lottie>
			</div>

		</div>
	)
}

export default LandingContent