import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function MyProjects(){

	const projectList = [
		{
			id: 1,
			projectName: 'Andronix',
			description: 'A basic Android app inspired from an open-source depreciated project to bring Linux\
						functionality to Android devices. It was started as a highschool summer break project with a friend.',
			date: 'August 2019',
			icon: 'fa-solid fa-play'
		},
		{
			id: 2,
			projectName: 'First FiveM resource',
			description: 'After 8 months of starting GTAV modding using FiveM, I released my first public resource.\
						A simple and modern game HUD for ESX framework.',
			date: 'December 2020',
			icon: 'fa-solid fa-road'
		},
		{
			id: 3,
			projectName: 'Bigger dive into FiveM',
			description: 'COVID-19 pandemic changed the way I spent my days. FiveM opened gates to a bigger open-source community\
						 which led to me new friends, introduced me to more experienced developers and better development enviornments.',
			date: 'February 2021',
			icon: 'fa-solid fa-road'
		},
		{
			id: 4,
			projectName: 'Devriz Technologies LLP',
			description: 'What started as a fun summer project, evolved into something big. After an immense amount of support\
						 and love from the community and Andronix userbase, we registered our first LLP and took our hobby project\
						 to another level.',
			date: 'July 2021',
			icon: 'fa-solid fa-building'
		},
		{
			id: 5,
			projectName: 'Next step towards learning',
			description: 'While exploring new things and playing games was fun, it was time to take next step in studies.\
							 I took admission in university as a first year CS student.',
			date: 'November 2021',
			icon: 'fa-solid fa-book-open'
		},
		{
			id: 6,
			projectName: 'Sollumz',
			description: 'GTAV modding still being one of my favourite things, I landed upon an open-source project to import\
							 end export GTAV assets using Blender. I`m actively trying to contribute and provide community support\
							 during my free time.',
			date: 'January 2022',
			icon: 'fa-solid fa-book-open'
		}
	]

	return(
		<div id='timeline' className="h-full font-rouna text-white bg-[#1E1E1E] bg-[url('./images/projects_bg.svg')]">
			<div className='py-10 3xl:py-10'>
				<div className='text-center text-4xl py-10 3xl:py-20 lg:text-7xl 3xl:text-9xl 4xl:text-[12rem]'>Timeline</div>
				<VerticalTimeline>
					{
						projectList.map((element) => {
							return (
								<VerticalTimelineElement
									key={element.id}
									contentStyle={{backdropFilter: 'blur(0.2rem)', background: 'rgba(255, 255, 255, 0.2)', WebkitBackdropFilter: 'blur(0.313rem)'}}
									contentArrowStyle={{display: 'none', }}
									iconStyle={{background:"#FFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', color: "#65C9FF", fontSize: "1.8rem" }}
									icon={<i className={element.icon}></i>}
								>
									<h3 className='vertical-timeline-element-title text-2xl text-profile-blue'>{element.projectName}</h3>
									<h3 className='vertical-timeline-element-subtitle '>{element.date}</h3>
									<p className='description text-zinc-200'>{element.description}</p>

								</VerticalTimelineElement>
							)
						})
						
					}
				</VerticalTimeline>
			</div>
		</div>
	)
}

export default MyProjects;
