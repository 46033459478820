import { useState } from "react";

function Nav(){
	let menuItems = [
		{id: 1, title:'About Me', link:"#about"},
		{id: 2, title:'Timeline', link:"#timeline"},
		{id: 3, title:'Contact', link:"#contact"}
	]

	const [openMenu, setOpenMenu] = useState(true)

	return(
			<nav className="absolute z-10 px-4 py-5 w-full top-0 flex flex-wrap font-rouna text-white">
				<a href="./index.html" className="text-xl">Anurag Verma</a>
				<div className="flex ml-auto lg:hidden">
					<button className={`fa-solid fa-bars`} onClick={()=>{setOpenMenu(!openMenu)}}></button>
				</div>
				
				<ul id="nav-items" className={`bg-[#27272A] rounded-lg lg:bg-transparent lg:ml-auto lg:inline-flex lg:w-auto w-full text-left transition-all ease-in-out duration-200 ${openMenu?"hidden":null}`}>
					{menuItems.map((item, index)=>{
						return(
						<li key={item.id} className="lg:pt-0 py-3">
							<a href={item.link}  className="hover:text-profile-blue ml-2 lg:mt-0 lg:px-6 lg:py-0 lg:text-xl" > {item.title} </a>
						</li>)
					})}
				</ul>
			</nav>
	)
}

export default Nav;